var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"visage-footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('br'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"col-md-10 col-md-offset-1 text-center margin-bottom-40"}),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticStyle:{"padding-top":"30px","margin":"0 auto"}},[_c('router-link',{staticClass:"nav-link w-nav-link",staticStyle:{"color":"#FFF"},attrs:{"to":{ path: '/politicas' }}},[_vm._v("- Políticas de Privacidad -")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"aspire-abouthover wow fadeInRight",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"aspire-aboutbox2"},[_c('a',{attrs:{"href":"https://ventas.iduam.com"}},[_c('img',{attrs:{"src":"/images/logo_iduam_MP.svg","height":"60"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"aspire-abouthover wow fadeInRight",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"aspire-aboutbox2"},[_c('a',{attrs:{"href":"https://ventasboton.sosclick.cl/"}},[_c('img',{attrs:{"src":"/images/logo-boton.svg","height":"75"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"aspire-abouthover wow fadeInRight",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"aspire-aboutbox2"},[_c('a',{attrs:{"href":"https://ventasadultomayor.sosclick.cl/"}},[_c('img',{attrs:{"src":"/images/LogoAdultoMayor.svg","height":"75"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-10 col-md-offset-1 text-center"},[_c('div',{staticClass:"visage-headings"},[_c('h5',{staticStyle:{"color":"white"}},[_vm._v("Cuéntale a tus amigos y recibe fabulosos descuentos en nuestros servicios")]),_c('img',{staticStyle:{"width":"200px"},attrs:{"src":"/images/naturalphone.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"visage-footer-box"},[_c('i',{staticClass:"fa fa-phone",staticStyle:{"color":"white"}}),_c('p',{staticStyle:{"color":"white"}},[_vm._v("Llámanos "),_c('br'),_vm._v("con tus preguntas")]),_c('span',{staticStyle:{"color":"white"}},[_vm._v("+56 2 22454622 "),_c('br'),_vm._v(" +56 2 22454618 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"visage-footer-box"},[_c('i',{staticClass:"fa fa-envelope-o",staticStyle:{"color":"white"}}),_c('p',{staticStyle:{"color":"white"}},[_vm._v("Contáctanos"),_c('br'),_vm._v(" con tus dudas")]),_c('span',{staticStyle:{"color":"white"}},[_vm._v("contacto@iduam.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 col-sm-4"},[_c('div',{staticClass:"visage-footer-box"},[_c('i',{staticClass:"fa fa-map-marker",staticStyle:{"color":"white"}}),_c('p',{staticStyle:{"color":"white"}},[_vm._v("Oficinas"),_c('br'),_vm._v("Santiago")]),_c('span',{staticStyle:{"color":"white","font-size":"12px"}},[_vm._v("Avenida Kennedy 5735,Hotel Marriott Torre Poniente,Oficina 1107")])])])
}]

export { render, staticRenderFns }