
<template>
  <div>
    <!--begin hero section-->
    <section class="zeus-video-section" id="home_wrapper">
      <div class="zeus-video-overlay"></div>

      <div id="player" class="video-background">
        <div class="video-foreground">
          <!--<iframe src="https://player.vimeo.com/video/316159723?autoplay=1&title=0&byline=0&portrait=0&muted=1" frameborder="0" allowfullscreen></iframe>-->
          <video
            id="videoBackground"
            muted
            loop
            style="width: 100%; height: 100%;"
          >
            <source src="../../assets/video/IduamECG.mp4" type="video/mp4" />
          </video>
        </div>
        <div class="video-overlay"></div>
      </div>

      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row">
          <!--begin col md 12 -->
          <div class="col-md-12">
            <div class="zeus-home-title text-center">
              <h1 style="color: white">IDUAM</h1>

              <p>
                Es un asistente que monitorea parámetros como la presión
                arterial, ritmo cardíaco, saturación de oxígeno, temperatura y
                permite realizar una monitorización electrocardiográfica la cual
                es interpretada a modo preventivo por un algoritmo de
                inteligencia artificial en forma constante y proactiva.
              </p>

              <!--begin popup-gallery-->
              <div class="popup-gallery">
                <!--a href="#" onclick="setVideo('https://www.youtube.com/embed/D-No29O0R5E')"-->
                <a
                  class="zeus-video-icon"
                  data-bs-target="#myModal" data-bs-toggle="modal"
                  @click="setVideo('https://www.youtube.com/embed/D-No29O0R5E')"
                >
                  <i class="fa fa-play"></i
                ></a>
              </div>
              <!--end popup-gallery-->

              <!-- redes sociales
                        <ul class="zeus-hero-social">

                        	<li><a href="#"><i class="fa fa-facebook"></i></a></li>
                        	<li><a href="#"><i class="fa fa-twitter"></i></a></li>
                        	<li><a href="#"><i class="fa fa-instagram"></i></a></li>
                        	<li><a href="#"><i class="fa fa-youtube"></i></a></li>

                        </ul>
						-->
            </div>
          </div>
          <!--end col md 12 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
    </section>
    <!--begin hero section-->
    <div
      style="
        background: transparent
          linear-gradient(180deg, #0064ed 0%, #00e4ed 100%) 0% 0% no-repeat;
      "
    >
      <div class="col-md-4" style="height: 230px; background-color: #0064ed">
        <div
          class="aspire-abouthover wow fadeInRight"
          data-wow-delay="0.25s"
          style="
            visibility: visible;
            animation-delay: 0.25s;
            animation-name: fadeInLeft;
            height: 220px;
          "
        >
          <div class="aspire-aboutbox2">
            <a href="https://ventas.iduam.com"
              ><img src="/images/logo_iduam_MP.svg" height="120"
            /></a>
          </div>
        </div>
      </div>
      <div class="col-md-4" style="height: 230px; background-color: #0064ed">
        <div
          class="aspire-abouthover wow fadeInRight"
          data-wow-delay="0.25s"
          style="
            visibility: visible;
            animation-delay: 0.25s;
            animation-name: fadeInLeft;
            height: 220px;
          "
        >
          <div class="aspire-aboutbox2">
            <a href="https://ventasboton.sosclick.cl/"
              ><img src="/images/logo-boton.svg" height="150"
            /></a>
          </div>
        </div>
      </div>
      <div class="col-md-4" style="height: 230px; background-color: #0064ed">
        <div
          class="aspire-abouthover wow fadeInRight"
          data-wow-delay="0.25s"
          style="
            visibility: visible;
            animation-delay: 0.25s;
            animation-name: fadeInLeft;
            height: 220px;
          "
        >
          <div class="aspire-aboutbox2">
            <a href="https://ventasadultomayor.sosclick.cl/"
              ><img src="/images/LogoAdultoMayor.svg" height="150"
            /></a>
          </div>
        </div>
      </div>
    </div>

    <!--div style="width: 100%;align-items: center;">
		<a href="https://ventas.iduam.com"><img src="/images/logo_iduam_MP.svg"   height="120"></a>

		<div style="width: 2px;background: #fff;height: 70%;margin: 0px 50px;" ></div>
		<a href="https://ventasboton.sosclick.cl/"><img src="/images/logo-boton.svg"   height="150"></a>

		<div style="width: 2px;background: #fff;height: 70%;margin: 0px 50px;"></div>
		<a href="https://ventasadultomayor.sosclick.cl/"><img src="/images/LogoAdultoMayor.svg"  height="150"></a>

	</div-->

    <!--begin section-image-->
    <section class="zeus-section-image">
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row">
          <!--begin col md 6 -->
          <div class="col-md-7">
            <div class="zeus-dark-headings">
              <h2>MONITOREAMOS CADA LATIDO</h2>

              <h1>IDUAM</h1>

              <p style="text-align: justify">
                En Chile muere una persona por infarto al corazón cada hora,
                situación que se repite si uno revisa las estadísticas a nivel
                Mundial.
              </p>

              <p style="text-align: justify">
                Las enfermedades cardiovasculares son la principal causa de
                muerte en el mundo y en nuestro país representa el 27,1% de las
                defunciones; ubicándose en primer lugar las enfermedades
                cerebrovasculares (ACV), seguidas por el infarto agudo al
                miocardio (IAM).
              </p>

              <p style="text-align: justify">
                En combinación con las smartband Iduam 600,
                Iduam te proporcionará información médica constantemente para
                que sepas si debes consultar a tu médico o si tus mediciones del
                ritmo cardíaco, presión arterial y saturación de oxígeno en la
                sangre se encuentran en los niveles óptimos. Lo mejor de todo es
                que podrás realizarte una monitorización electrocardiográfica
                desde nuestra App, la cual será interpretada a modo preventivo
                por un algoritmo de inteligencia artificial.
              </p>

              <div class="zeus-featbox2 wow fadeInLeft" data-wow-delay="0.75s">
                <img src="/images/pulsera.svg" alt="feature-image" />

                <div class="zeus-featbox2-text">
                  <h4>SmartBands</h4>
                  <p>
                    Son indispensables debido a que estas pulseras
                    cuantificadoras nos permiten monitorear tus parámetros
                    cardiovasculares con la finalidad de ayudarte a mejorar tu
                    estilo de vida.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col md 6 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
    </section>
    <!--end section image -->

    <!--begin section-white-->
    <section class="section-white-xs" id="gallery">
      <!--begin container -->
      <div class="container-fluid">
        <!--begin row -->
        <div class="row">
          <!--begin col md 12
		        <div class="col-md-12">

		        	<div class="zeus-dark-headings-center margin-bottom-60">

		        		<h2>Gallery</h2>

		        		<h3>Zeus in ./images</h3>

		        	</div>

				</div>
		        end col md 12 -->

          <!--begin col md 6 -->
          <div class="col-md-6">
            <!--begin popup-gallery-->
            <div class="popup-gallery popup-gallery-rounded portfolio-pic">
              <a
                href="#"
                @click="setVideo('https://www.youtube.com/embed/D-No29O0R5E')"
              >
                <!--a href="#" onclick="setVideo('https://player.vimeo.com/video/316159723')"-->
                <div>
                  <div class="Texto-video">
                    Realiza tu <br />
                    propios ECG
                  </div>
                  <img
                    src="/images/video-1.png"
                    alt="gallery-image"
                    class="width-100"
                  />
                  <span class="eye-wrapper"
                    ><i class="fa fa-play eye-icon" style="font-size: 38px"></i
                  ></span>
                </div>
              </a>
            </div>
            <!--end popup-gallery-->

            <!--begin col md 6 -->
            <div class="col-sm-6 margin-top-30 gallery-inner1">
              <!--begin popup-gallery-->
              <div class="popup-gallery popup-gallery-rounded portfolio-pic">
                <a
                  href="#"
                  @click="setVideo('https://www.youtube.com/embed/59FJhu1f85E')"
                >
                  <!--a href="#" onclick="setVideo('https://player.vimeo.com/video/270092796')"-->
                  <div>
                    <div class="Texto-video">
                      Consejos de <br />
                      Salud
                    </div>
                    <img
                      src="/images/video-4.png"
                      alt="gallery-image"
                      class="width-100 zeus-gallery"
                    />
                    <span class="eye-wrapper"
                      ><i
                        class="fa fa-play eye-icon"
                        style="font-size: 38px"
                      ></i
                    ></span>
                  </div>
                </a>
              </div>
              <!--end popup-gallery-->
            </div>
            <!--end col md 6-->

            <!--begin col md 6 -->
            <div class="col-sm-6 margin-top-30 gallery-inner2">
              <!--begin popup-gallery-->
              <div class="popup-gallery popup-gallery-rounded portfolio-pic">
                <a
                  href="#"
                  @click="setVideo('https://www.youtube.com/embed/xlyLIXa2Dz0')"
                >
                  <!--a href="#" onclick="setVideo('https://player.vimeo.com/video/270092624')"-->
                  <div>
                    <div class="Texto-video">
                      Asistente médico <br />
                      personal
                    </div>
                    <img
                      src="/images/video-3.png"
                      alt="gallery-image"
                      class="width-100 zeus-gallery"
                    />
                    <span class="eye-wrapper"
                      ><i
                        class="fa fa-play eye-icon"
                        style="font-size: 38px"
                      ></i
                    ></span>
                  </div>
                </a>
              </div>
              <!--end popup-gallery-->
            </div>
            <!--end col md 6-->
          </div>
          <!--end col md 6-->

          <!--begin col md 6 -->
          <div class="col-md-6">
            <!--begin popup-gallery-->
            <div class="popup-gallery popup-gallery-rounded portfolio-pic">
              <a
                href="#"
                @click="setVideo('https://www.youtube.com/embed/RW_EclTXHxk')"
              >
                <!--a href="#" onclick="setVideo('https://player.vimeo.com/video/270093260')"-->
                <div>
                  <div class="Texto-video-2">
                    Monitoreamos <br />
                    cada latido
                  </div>
                  <img
                    src="/images/video-2.png"
                    alt="gallery-image"
                    class="width-100"
                  />
                  <span class="eye-wrapper"
                    ><i class="fa fa-play eye-icon" style="font-size: 38px"></i
                  ></span>
                </div>
              </a>
            </div>
            <!--end popup-gallery-->
          </div>
          <!--end col md 6-->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
    </section>
    <!--end section white -->

    <!--begin section dark -->
    <section class="section-dark otra" id="Servicios">
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row">
          <!--begin col md 12 -->
          <div class="col-md-12 text-center margin-bottom-20">
            <div class="aspire-headings">
              <h2>SERVICIOS</h2>
            </div>
          </div>
          <!--end col md 12 -->

          <!--begin col md 4 -->
          <div class="col-md-4">
            <div
              class="aspire-abouthover wow fadeInRight"
              data-wow-delay="0.25s"
              style="
                visibility: visible;
                animation-delay: 0.25s;
                animation-name: fadeInLeft;
              "
            >
              <div class="aspire-abouticon">
                <img
                  src="/images/mediciones.png"
                  style="width: 20%"
                  alt="aspire-icon"
                />
              </div>

              <div class="aspire-aboutbox2">
                <h5><strong> MEDICIONES DE SALUD </strong><br />MANUALES</h5>

                <p style="text-align: justify">
                  Mide de manera sencilla tus signos vitales con las Smartband
                  Iduam, que en conjunto con nuestra Aplicación Móvil Iduam te
                  permitirá medir tu Ritmo Cardíaco, Presión Arterial y realizar
                  tu propia monitorización Electrocardiográfica. Iduam arrojará
                  tus resultados de manera inmediata, interpretados por un
                  algoritmo de inteligencia artificial que te dirá si estás bien
                  o tienes que consultar a tu médico.<br /><br />
                </p>
              </div>
            </div>
          </div>
          <!--end col md 4-->

          <!--begin col md 4-->
          <div class="col-md-4">
            <div
              class="aspire-abouthover wow fadeInRight"
              data-wow-delay="0.75s"
              style="
                visibility: visible;
                animation-delay: 0.35s;
                animation-name: fadeInUp;
              "
            >
              <div class="aspire-abouticon">
                <img
                  src="/images/24.png"
                  style="width: 22%"
                  alt="aspire-icon"
                />
              </div>

              <div class="aspire-aboutbox2">
                <h5><strong>MEDICIONES</strong> <br />ALERTAS</h5>

                <P style="text-align: justify"
                  >Para mayor precisión estadística en los niveles de Presión
                  Arterial, Saturación de Oxígeno y Ritmo cardíaco, disponemos
                  de la herramienta tipo Holter, la cual te permite realizar
                  varias mediciones en un mismo día manteniendo en todo momento
                  tu información sobre tus parámetros de salud, con la finalidad
                  de poder prevenir una posible urgencia. Además, mantiene tu
                  historial de parámetros de salud actualizados.<br /><br
                /></P>
              </div>
            </div>
          </div>
          <!--end col md 4-->

          <!--begin col md 4 -->
          <div class="col-md-4">
            <div
              class="aspire-abouthover wow fadeInRight"
              data-wow-delay="1.0s"
              style="
                visibility: visible;
                animation-delay: 0.45s;
                animation-name: fadeInRight;
              "
            >
              <div class="aspire-abouticon">
                <img src="/images/alerta.png" style="width: 17%" />
              </div>

              <div class="aspire-aboutbox2">
                <h5><strong>Monitorización</strong> <br />(próximamente)</h5>

                <P style="text-align: justify"
                  >Cuando contratas Iduam y sus servicios empleas la más
                  especializada red de servicios de asistencia médica existente
                  en el país, conformada por un equipo interdisciplinario de
                  expertos en salud, telecomunicaciones y tecnología. Gracias al
                  esfuerzo de todos y cada uno de ellos, hemos conseguido crear
                  un sistema que monitorea el estado de salud de nuestros
                  pacientes de forma permanente y que brinda asistencia durante
                  el día, de forma preventiva y reactiva.</P
                >
              </div>
            </div>
          </div>
          <!--end col md 4-->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
    </section>
    <!--end section dark -->

    <!--begin section-grey-->
    <section class="section-telefono" id="aplicacion">
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row">
          <!--begin col md 12 -->
          <div class="col-md-12">
            <div class="luna-headings-dark-center text-center white-text">
              <h2>LA APLICACIÓN</h2>

              <h1>CONOCE TODAS LAS CARACTERÍSTICAS</h1>

              <p>
                Con la aplicación Iduam en conjunto con la smartband Iduam
                600, podrás monitorearte en todo momento
                al ejecutar mediciones de tu ritmo cardíaco, presión arterial,
                saturación de oxígeno y realizarte una monitorización
                electrocardiográfica desde nuestra aplicación, la cual te
                mostrará si estas bien, o tienes que consultar a tu Médico.
              </p>
            </div>
          </div>
          <!--end col md 12 -->

          <!--begin col md 4 -->
          <div class="col-md-4 padding-top-40">
            <div
              class="luna-featbox2 wow fadeInLeft"
              data-wow-delay="0.25s"
              style="
                visibility: visible;
                animation-delay: 0.25s;
                animation-name: fadeInLeft;
              "
            >
              <div class="luna-icon-wrap">
                <div
                  class="luna-featbox2-icon"
                  onclick="cambiar_imagen_phone(1)"
                >
                  <i class="fa fa-heartbeat"></i>
                </div>
              </div>

              <div class="luna-featbox2-text">
                <h3>Monitorización electrocardiográfica</h3>

                <p style="text-align: justify">
                  Realízate tus electrocardiográmas desde el App de Iduam el que
                  será interpretado en forma preventiva por un algoritomo de
                  inteligencia artificial.
                </p>
              </div>
            </div>

            <div
              class="luna-featbox2 wow fadeInLeft"
              data-wow-delay="0.45s"
              style="
                visibility: visible;
                animation-delay: 0.45s;
                animation-name: fadeInLeft;
              "
            >
              <div class="luna-icon-wrap">
                <div
                  class="luna-featbox2-icon"
                  onclick="cambiar_imagen_phone(2)"
                >
                  <i class="fa fa-heart"></i>
                </div>
              </div>

              <div class="luna-featbox2-text">
                <h3>Ritmo Cardíaco</h3>

                <p style="text-align: justify">
                  Conoce tu ritmo cardíaco de manera muy simple desde el App de
                  Iduam, desde ahora no necesitarás movilizarte a ningún
                  hospital o centro médico.
                </p>
              </div>
            </div>

            <div
              class="luna-featbox2 wow fadeInLeft"
              data-wow-delay="0.65"
              style="
                visibility: visible;
                animation-delay: 0.65s;
                animation-name: fadeInLeft;
              "
            >
              <div class="luna-icon-wrap">
                <div
                  class="luna-featbox2-icon"
                  onclick="cambiar_imagen_phone(3)"
                >
                  <i class="fa fa-tint"></i>
                </div>
              </div>

              <div class="luna-featbox2-text">
                <h3>Presión Arterial</h3>

                <p style="text-align: justify">
                  Realízate en un instante tu prueba de presión arterial desde
                  nuestra Aplicación móvil Iduam.
                </p>
              </div>
            </div>

            <div
              class="luna-featbox2 wow fadeInLeft"
              data-wow-delay="0.85s"
              style="
                visibility: visible;
                animation-delay: 0.85s;
                animation-name: fadeInLeft;
              "
            >
              <div class="luna-icon-wrap">
                <div
                  class="luna-featbox2-icon"
                  onclick="cambiar_imagen_phone(4)"
                >
                  <i class="fa fa-tachometer"></i>
                </div>
              </div>

              <div class="luna-featbox2-text">
                <h3>Saturación de Oxígeno</h3>

                <p style="text-align: justify">
                  Usando las Smartband de Iduam podrás saber al instante tu
                  saturación de oxígeno en la sangre.
                </p>
              </div>
            </div>
          </div>
          <!--end col md 4 -->

          <!--begin col md 4 -->
          <div class="col-md-4">
            <div class="luna-imagewrap2 text-center">
              <img
                id="imagen_telefono_id"
                src="/images/img-0.png"
                alt="about-image"
                class="width-100 wow fadeInUp"
                data-wow-delay="0.85s"
                style="
                  visibility: visible;
                  animation-delay: 0.85s;
                  animation-name: fadeInUp;
                "
              />
            </div>
          </div>
          <!--end col md 4 -->

          <!--begin col md 4 -->
          <div class="col-md-4">
            <div
              class="luna-featbox3 wow fadeInRight"
              data-wow-delay="0.25s"
              style="
                visibility: visible;
                animation-delay: 0.25s;
                animation-name: fadeInRight;
              "
            >
              <div class="luna-icon-wrap">
                <div
                  class="luna-featbox3-icon"
                  onclick="cambiar_imagen_phone(5)"
                >
                  <i class="fa fa-video-camera"></i>
                </div>
              </div>

              <div class="luna-featbox3-text">
                <h3>Monitoreo</h3>

                <p style="text-align: justify">
                  Levanta en tiempo real tu monitoreo de tu imagen y podrás ser
                  visualizado en un panel web remotamente cuando nuestra empresa
                  ofrezca dicho servicio.<br /><br /><br />
                </p>
              </div>
            </div>

            <div
              class="luna-featbox3 wow fadeInRight"
              data-wow-delay="0.45s"
              style="
                visibility: visible;
                animation-delay: 0.45s;
                animation-name: fadeInRight;
              "
            >
              <div class="luna-icon-wrap">
                <div
                  class="luna-featbox3-icon"
                  onclick="cambiar_imagen_phone(6)"
                >
                  <i class="fa fa-shield"></i>
                </div>
              </div>

              <div class="luna-featbox3-text">
                <h3>Red de Seguridad</h3>

                <p style="text-align: justify">
                  Genera tu red de emergencia de salud personal la cual ante una
                  alerta se podrán comunicar por video conferencia para
                  prestarte la ayuda que necesites.<br /><br /><br />
                </p>
              </div>
            </div>

            <!--div class="luna-featbox3 wow fadeInRight" data-wow-delay="0.65s" style="visibility: visible; animation-delay: 0.65s; animation-name: fadeInRight;">

		        		<div class="luna-icon-wrap">

			        		<div class="luna-featbox3-icon"onclick="cambiar_imagen_phone(7)">
			        		<i class="fa fa-calendar-o"></i>
			        		</div>
		        		</div>

		        		<div class="luna-featbox3-text">

		        			<h3>Recordatorios</h3>

		        			<p>Crea tus recordatorios de mediciones y medicinas.<br><br></p>

		        		</div>

		        	</div-->

            <div
              class="luna-featbox3 wow fadeInRight"
              data-wow-delay="0.85s"
              style="
                visibility: visible;
                animation-delay: 0.85s;
                animation-name: fadeInRight;
              "
            >
              <div class="luna-icon-wrap">
                <div
                  class="luna-featbox3-icon"
                  onclick="cambiar_imagen_phone(8)"
                >
                  <i class="fa fa-bar-chart"></i>
                </div>
              </div>

              <div class="luna-featbox3-text">
                <h3>Historial</h3>

                <p style="text-align: justify">
                  Revisa en cualquier momento tu historial de mediciones médicas
                  desde tu celular o desde nuestro administrador web.<br /><br /><br />
                </p>
              </div>
            </div>
          </div>
          <!--end col md 4 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
    </section>
    <!--end section grey-->

    <!--begin section rose-->
    <section class="section-rose" id="cuida">
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row">
          <!--begin col md 6 -->
          <div class="col-md-6">
            <div
              class="zeus-dark-headings-about wow fadeInLeft"
              data-wow-delay="“0.60s”"
              id="Tata"
              style="visibility: 'visible'
              animation-delay:
              0.6s;
              animation-name:
              fadeInLeft;"
            >
              <h1 style="padding-bottom: 20px">
                <strong>TE PRESENTAMOS IDUAM </strong>
              </h1>
              <p style="text-align: justify">
                Nuestra aplicación móvil esta diseñada y desarrollada para
                cualquier persona para que se monitoree sus parámetros de salud
                en forma preventiva tales como ritmo cardíaco, stauración de
                oxígeno, presión arterial, temperatura y monitorización
                electrocariográfica.
              </p>
              <p style="text-align: justify">
                Si tienes alguna emergencia de salud, presiona el botón de
                alerta y podrás recibir ayuda de tu red de seguridad
                personal.<br /><br /><br />
              </p>
              <!--a class="luna-featbox3-text btn-conocesmas" href="https://www.iduamtata.com/" style="margin-left: 150px; padding-left: 50px;padding-right: 50px;">Conocer más</a-->
            </div>
          </div>
          <!--end col md 6 -->

          <!--begin col md 6 -->
          <div class="col-md-6">
            <img
              src="/images/abuela.png"
              alt="about-image"
              class="width-100 wow"
              data-wow-delay="0.60s"
              bottom
            />
          </div>
          <!--end col md 6 -->
        </div>
        <!--end row -->

        <!--begin row -->
        <div class="“row”">
          <!--begin col md 6 -->
          <div class="col-md-6 hidden-sm hidden-xs">
            <img
              src="/images/compu.png"
              alt="about-image"
              class="width-100 wow"
              data-wow-delay="0.60s"
              bottom
            />
          </div>
          <!--end col md 6 -->

          <!--begin col md 6 -->
          <div class="col-md-6">
            <div
              class="zeus-dark-headings-about wow fadeInLeft"
              data-wow-delay="0.60s"
            >
              <h1 style="padding-bottom: 20px; padding-top: 60px">
                AL GENERAR UNA <strong>ALERTA</strong>
              </h1>

              <p style="text-align: justify">
                Inmediatamente se generará una notificación a tu red de
                seguridad personal y cuando nuestra empresa ofrezca el servicio
                de atención médica, y tengas contratado dicho servicio, también
                se levantará una video conferencia con nuestro personal médico.
              </p>
              <p>
                Con <strong>IDUAM</strong> podrás acceder incluso a tu historial
                médico en el panel web de usuarios.
              </p>
              <h2 style="text-align: right; color: black">
                MONITOREAMOS CADA LATIDO
              </h2>
            </div>
          </div>
          <!--end col md 6 -->

          <!--begin col md 6 -->
          <div class="col-md-6 hidden-md hidden-lg">
            <img
              src="/images/compu.png"
              alt="about-image"
              class="width-100 wow imgaux"
              data-wow-delay="0.60s"
              bottom
            />
          </div>
          <!--end col md 6 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
    </section>
    <!--end section rose -->

    <!--begin section white-->
    <section class="section-descripciones" id="features">
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row text-center">
          <!--begin col md 12 -->
          <div class="col-md-12">
            <div class="zeus-dark-headings-center black-text">
              <h2 style="color: black">Para una vida más plena</h2>

              <h3 style="color: black">
                Características de Nuestros servicios
              </h3>
            </div>
          </div>
          <!--end col md 12 -->
        </div>
        <!--end row -->

        <!--begin row -->
        <div class="row">
          <!--begin col md 4 -->
          <div class="col-sm-4">
            <div class="aspire-des text-center">
              <i class="fa fa-heartbeat"></i>
              <h3>Medimos</h3>
              <p style="text-align: justify">
                Tu presión arterial, ritmo cardíaco, saturación de oxígeno,
                temperatura y monitorización electrocardiográfica usando la
                aplicación, determinando así tu estado de salud actual.
              </p>
            </div>
          </div>
          <!--end col md 4 -->

          <!--begin col md 4 -->
          <div class="col-sm-4">
            <div class="aspire-des text-center">
              <i class="fa fa-exclamation-triangle"></i>
              <h3>Alertas</h3>
              <p style="text-align: justify">
                Esta medición se envía de forma automática a la red de
                emergencia personal con el fin de que las personas que tu hayas
                incorporado puedan comunicarse contigo para asistirte por video
                conferencia.
              </p>
            </div>
          </div>
          <!--end col md 4 -->

          <!--begin col md 4 -->
          <div class="col-sm-4">
            <div class="aspire-des text-center">
              <i class="fa fa-address-card"></i>
              <h3>Ficha</h3>
              <p style="text-align: justify">
                En esta sección tu podrás acceder a tus mediciones historicas
                las cuales pueden ser mostradas a tu médico personal.<br /><br />
              </p>
            </div>
          </div>
          <!--end col md 4 -->
        </div>
        <!--end row -->

        <!--begin row -->
        <div class="row">
          <img
            src="/images/image2.png"
            alt="image-zeus"
            class="width-100 wow fadeInUp"
            data-wow-delay="0.60s"
          />
        </div>
        <!--end row -->
      </div>
      <!--end container -->

      <!--end section white -->

      <!--begin section white-->
      <section class="section-white" id="smartband">
        <!--begin container -->
        <div class="container">
          <!--begin row -->
          <div class="row">
            <!--begin col md 6 -->
            <div class="col-md-6">
              <div
                class="zeus-dark-headings-about wow fadeInLeft"
                data-wow-delay="0.60s"
              >
                <h1 style="padding-bottom: -5px">Iduam 600</h1>
                <div><img src="/images/linea.svg" /></div>

                <p style="text-align: justify">
                  La Iduam 600 te permite medir tu ritmo cardíaco, presión
                  arterial, saturación de oxígeno y realizarte un electro
                  cardiograma desde nuestra aplicación el cual será interpretado
                  por un algoritmo de inteligencia artificial.
                </p>
                <p style="text-align: justify">
                  La Iduam 600 esta equipada con el Ti 1292 IC para la función
                  de ECG, basado en este IC, los resultados de las pruebas de
                  presión arterial serán más precisos, especialmente para las
                  personas hipertensas.
                </p>
                <p style="text-align: justify">
                  La monitorización electrocardiográfica analizará de forma
                  manual y precisa el estado del corazón. Combinado de ECG y
                  PPG, y junto con nuestros algoritmos de inteligencia
                  artificial, los resultados serán más precisos y eficientes.
                </p>
              </div>
            </div>
            <!--end col md 6 -->

            <!--begin col md 6 -->
            <div class="col-md-6">
              <img
                src="/images/14.png"
                alt="about-image"
                class="width-100 wow fadeInRight"
                data-wow-delay="0.60s"
                bottom
                style="margin-left: 100px; max-width: 250px;"
              />
            </div>
            <!--end col md 6 -->
          </div>
          <!--end row -->
        </div>
        <!--end container -->
      </section>
      <!--end section white -->

      <!--begin section video-->
      <section class="aspire-video-wrapper">
        <!--begin container -->
        <div class="container">
          <!--begin row -->
          <div class="row">
            <!--begin col md 12 -->
            <div class="col-md-12 text-center">
              <div class="popup-gallery">
                <h4>Monitoreamos Cada Latido</h4>
                <!--a href="#" onclick="setVideo('https://www.youtube.com/embed/xlyLIXa2Dz0')"-->
                <a
                  class="aspire-video-icon"
                  @click="setVideo('https://www.youtube.com/embed/RW_EclTXHxk')"
                >
                  <i class="fa fa-play"></i
                ></a>
              </div>
            </div>
            <!--end col md 4 -->
          </div>
          <!--end row -->
        </div>
        <!--end container -->
      </section>
      <!--end section video -->

      <!--begin section dark-->
      <section class="section-dark">
        <!--begin container -->
        <div class="container">
          <!--begin row -->
<!--           <div class="row">

            <div class="col-md-6">
              <img
                src="/images/Life-one-m8.png"
                alt="about-image"
                class="width-100 wow fadeInLeft"
                data-wow-delay="0.60s"
              />
            </div>
            <div class="col-md-6">
              <div
                class="zeus-dark-headings-about wow fadeInRight"
                data-wow-delay="0.60s"
              >
                <h3>M8</h3>

                <p style="text-align: justify">
                  Con la tecnología de monitoreo progresivo, la pulsera es capaz
                  de rastrear su ritmo cardíaco con precisión.
                </p>

                <p style="text-align: justify">
                  Al transferir los datos dinámicos a la aplicación, podrá
                  obtener una vista general de su condición cardíaca.
                </p>

                <p style="text-align: justify">
                  El sensor óptico registra en tiempo real los cambios en el
                  Ritmo Cardíaco y lo interpretará con nuestro algoritmo de
                  inteligencia artificial para que finalmente determine tu
                  presión arterial.
                </p>
              </div>
            </div>
          </div> -->
          <!--end row -->
        </div>
        <!--end container -->
      </section>
      <!--end section dark -->

      <!--begin section-white-->
      <section class="section-rose">
        <!--begin container -->
        <div class="container">
          <!--begin row -->
          <div class="row">
            <!--begin col md 12 -->
            <div class="col-md-12">
              <div class="tabs">
                <nav class="tabs-nav col-md-6">
                  <div class="zeus-dark-headings">
                    <h2>Muy Pronto</h2>

                    <h3>ADQUIERE LA TUYA</h3>

                    <p>Elige la que se adapte mejor a tus necesidades.</p>
                  </div>

                  <ul>
                    <li data-tab="1" class="tab-item-zeus">
                      <h4>Iduam 600</h4>
                      <p></p>
                    </li>
<!--                     <li data-tab="2" class="tab-item-zeus">
                      <h4>Iduam M8</h4>
                      <p></p>
                    </li> -->
                  </ul>
                </nav>

                <div class="tab-container col-md-6">
                  <div class="tab-content text-center" data-tab="1">
                    <img src="/images/14.png" alt="zeus-image" style="max-width: 220px;" />

                    <p style="text-align: justify">
                      La Iduam 600 te permite medir tu Ritmo Cardíaco, Presión
                      Arterial, Saturación de Oxígeno, Temperatura y podrás
                      realizarte un Electrocardiograma desde nuestra Aplicación
                      Móvil o lectura en la propia Smartband.La Iduam 600 esta
                      equipada con el Ti 1292 IC para la función de ECG, basado
                      en este IC, los resultados de las pruebas de presión
                      arterial serán más precisos, especialmente para las
                      personas hipertensas.
                    </p>
                  </div>

                  <div class="tab-content text-center" data-tab="2">
                    <img src="/images/Life-one-m8-p-280.png" alt="zeus-image" />

                    <p style="text-align: justify">
                      Con la tecnología de monitoreo progresivo, la pulsera es
                      capaz de rastrear tu Ritmo Cardíaco con mayor precisión,
                      como así támbien, tu Presión Arterial y Saturación de
                      Oxígeno. También podrás realizarte una monitorización
                      electrocardiográfica o ECG, la cual será interpretada a
                      modo de información por un algoritmo de inteligencia
                      artificial.
                    </p>
                  </div>

                  <div class="tab-content text-center" data-tab="3">
                    <img src="/images/shop3.png" alt="zeus-image" />

                    <p>
                      Registra los niveles de glucosa en la sangre a través de
                      un ingreso manual o mediante un dispositivo inalámbrico
                    </p>

                    <a href="#" class="zeus-btn">Muy Pronto</a>
                  </div>
                </div>
              </div>
            </div>
            <!--end col md 12-->
          </div>
          <!--end row -->
        </div>
        <!--end container -->
      </section>
      <!--end section white -->

      <!--begin section-white-->
      <section class="section-precios" id="pricing">
        <!--begin container -->
        <div class="container">
          <!--begin row -->
          <div class="row">
            <!--begin col md 12 -->
            <div class="col-md-12">
              <div class="luna-headings-dark-center text-center">
                <h2>Precios</h2>

                <h3>¡Tenemos el Plan perfecto para ti!</h3>

                <p>Te cuidamos en todo momento con nuestros servicios.</p>
              </div>
            </div>
            <!--end col md 12 style="width: 50% !important"-->

            <!--begin col md 4 -->
            <div class="col-md-12 col-sm-12">
              <div
                class="luna-pricebox wow fadeInRight"
                data-wow-delay="0.25s"
                style="
                  visibility: visible;
                  animation-delay: 0.25s;
                  animation-name: fadeInRight;
                "
              >
                <div class="price-top">
                  <h4>Plan Básico</h4>

                  <p class="price">
                    <span class="currency">$</span>
                    <span class="number">{{basicPlanPrice | formatNumber}} IVA Incl.</span>
                    <span class="period">Anual</span>
                  </p>
                </div>

                <div class="price-bottom">
                  <ul>
                    <li>
                      <i class="fa fa-check"></i>Aplicación Móvil y Smartband
                    </li>
                    <li>
                      <i class="fa fa-check"></i>Realizar mediciones manuales y
                      Automáticas tipo Holter
                    </li>
                    <li>
                      <i class="fa fa-check"></i>ECG o Electrocardioagrama con
                      interpretación de Inteligencia Artificial (IA)
                    </li>
                    <li>
                      <i class="fa fa-check"></i>Historial de mediciones en la
                      aplicación móvil y en el Portal Web
                    </li>
                    <li class="last">
                      <i class="fa fa-check"></i>Red de Seguridad y Emergencia
                      Personal (5 usuarios)
                    </li>
                    <!--li class="last"><i class="fa fa-close"></i>Consulta online</li-->
                  </ul>

                  <a href="https://ventas.iduam.com/Checkout?plan=1" class="btn-price"
                    >Comprar</a
                  >
                </div>
              </div>
            </div>
            <!--end col md 4 -->

            <!--begin col md 4 -->
<!--            <div class="col-md-6 col-sm-6">-->
<!--              <div class="luna-pricebox">-->
<!--                <div class="price-top">-->
<!--                  <h4>Plan Premium (Proxímamente)</h4>-->

<!--                  <p class="price">-->
<!--                    <span class="currency">$</span>-->
<!--                    <span class="number">349.990</span>-->
<!--                    <span class="period">Bruto Anual</span>-->
<!--                  </p>-->
<!--                </div>-->

<!--                <div class="price-bottom">-->
<!--                  <ul>-->
<!--                    <li>-->
<!--                      <i class="fa fa-check"></i>Aplicación Móvil y Smartband-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <i class="fa fa-check"></i>Realizar mediciones manuales y-->
<!--                      Automáticas tipo Holter-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <i class="fa fa-check"></i>ECG o Electrocardioagrama con-->
<!--                      interpretación de Inteligencia Artificial (IA)-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <i class="fa fa-check"></i>Historial de mediciones en la-->
<!--                      Aplicación Móvil y en el Portal Web-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <i class="fa fa-check"></i>Red de Seguridad y Emergencia-->
<!--                      Personal (5 usuarios)-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <i class="fa fa-check"></i>Monitoreo online Anual en-->
<!--                      horario diurno oficina-->
<!--                    </li>-->
<!--                    <li class="last">-->
<!--                      <i class="fa fa-check"></i>Consulta Médica online (Una por-->
<!--                      mes)-->
<!--                    </li>-->
<!--                  </ul>-->

<!--                  <a href="https://ventas.iduam.com" class="btn-price"-->
<!--                    >Ir a Iduam MarketPlace</a-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <!--end col md 4 -->
          </div>
          <!--end row -->
        </div>
        <!--end container -->
      </section>
      <!--end section white -->
    </section>
    <!-- Modal -->
    <!-- <div
      class="modal fade"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"


    > -->
    <div
     v-if="showModal"
     style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 9999; background-color: rgba(0, 0, 0, 0.5);"

    >
      <!-- <div class="vertical-alignment-helper"> -->
        <div
          class="modal-dialog vertical-align-center"
          style="width: 100vw; height: 100vh"
        >
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                @click="closeVideo()"
                style="margin-right: 12px; opacity: 1; color: #fff"
              >
                <span aria-hidden="true"
                  ><i
                    class="fa fa-arrow-left"
                    style="margin-right: 12px"
                  ></i></span
                >Volver
              </button>
            </div>
            <div class="modal-body">
              <div style="padding: 56.25% 0 0 0; position: relative">
                <iframe
                  width="640"
                  height="360"
                  id="url_video"
                  :src="url_video"
                  frameborder="0"
                  allowfullscreen
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 90vh;
                  "
                ></iframe>

                <!--iframe id="url_video" src="" style="position:absolute;top:0;left:0;width:100vw;height:90vh;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe-->
              </div>
              <!--script src="https://player.vimeo.com/api/player.js"></script-->
            </div>
          </div>
        </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
  getFinalPaymenInfo
} from "../helpers/api/planes";
export default {
  name: "Home",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    url_video: "",
    showModal: false,
    basicPlanPrice: ''
  }),
  mounted() {
    // if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
    //   this.getOrderStatus(this.$route.query.token);
    // }

    getFinalPaymenInfo({product_id: 1, qty: 1,}).then(res => {
      console.log(res);
      this.basicPlanPrice = res.final_price
    })
  },
  methods: {
    setVideo(url) {
      console.log(url);
      this.url_video = url;
      // show bootstrap modal
      this.showModal = true;
      window.scrollTo(0, 0);

    },
    closeVideo() {
      this.url_video = "";
      this.showModal = false;
    },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
          planPayment.flowData &&
          planPayment.flowData.url &&
          planPayment.flowData.token
        ) {
          const payUrl =
            planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.media
            : "",
          fecha: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.date
            : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
  filters: {
    formatNumber: function(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>

<style>
/* @import "../../assets/css/bootstrap.css"; */
@import "../../assets/css/style.css";
@import "../../assets/css/animate.css";
@import "../../assets/css/style-magnific-popup.css";

/* [data-wf-bgvideo-fallback-img] {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
} */
</style>

