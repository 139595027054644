<template>
  <div>
    <Header />
    <Home />
    <Footer />
    <Drawer />
  </div>
</template>

<script>
import Home from "./Home.vue";
import Drawer from "../drawer/Drawer.vue";
import Header from "../commons/Header.vue";
import Footer from "../commons/Footer.vue";

export default {
  name: "Index",
  components: {
    Home,
    Drawer,
    Header,
    Footer,
  },
  data() {
    return {
      key: 0,
    };
  },
  methods: {},
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "/js/bootstrap.js");
    document.head.appendChild(plugin);

    const plugin2 = document.createElement("script");
    plugin2.setAttribute("src", "/js/jquery-1.11.3.min.js");
    document.head.appendChild(plugin2);

    const plugin3 = document.createElement("script");
    plugin3.setAttribute("src", "/js/jquery.scrollTo-min.js");
    document.head.appendChild(plugin3);

    const plugin4 = document.createElement("script");
    plugin4.setAttribute("src", "/js/jquery.magnific-popup.min.js");
    document.head.appendChild(plugin4);

    const plugin5 = document.createElement("script");
    plugin5.setAttribute("src", "/js/jquery.nav.js");
    document.head.appendChild(plugin5);

    const plugin6 = document.createElement("script");
    plugin6.setAttribute("src", "/js/wow.js");
    document.head.appendChild(plugin6);

    const plugin7 = document.createElement("script");
    plugin7.setAttribute("src", "/js/countdown.js");
    document.head.appendChild(plugin7);

    const plugin8 = document.createElement("script");
    plugin8.setAttribute("src", "/js/custom.js");
    document.head.appendChild(plugin8);

    const plugin9 = document.createElement("script");
    plugin9.setAttribute("src", "/js/plugins.js");
    document.head.appendChild(plugin9);

  },
};
</script>

<style>
</style>